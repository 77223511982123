.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
}
blockquote.font-small p {
  font-size: small;
}

body {
  overflow: scroll;
}

a:not([href]).link,
a:not([href]).link:hover {
  /*color: #007bff;*/
  text-underline: #007bff;
  cursor: pointer;
  text-decoration: dashed;
  text-decoration-line: underline;
}
